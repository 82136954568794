var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("Icon", { attrs: { type: "ios-information-circle" } }),
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ],
        1
      ),
      _c(
        "Form",
        {
          ref: "formValidate",
          attrs: {
            model: _vm.merchantRole,
            rules: _vm.rules,
            "label-width": 120
          }
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "角色名称", prop: "name" } },
            [
              _c("Input", {
                attrs: { placeholder: "请输入角色名称" },
                model: {
                  value: _vm.merchantRole.name,
                  callback: function($$v) {
                    _vm.$set(_vm.merchantRole, "name", $$v)
                  },
                  expression: "merchantRole.name"
                }
              })
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "角色描述", prop: "description" } },
            [
              _c("Input", {
                attrs: { placeholder: "请输入角色描述" },
                model: {
                  value: _vm.merchantRole.description,
                  callback: function($$v) {
                    _vm.$set(_vm.merchantRole, "description", $$v)
                  },
                  expression: "merchantRole.description"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Divider"),
      _vm.resourceList.length
        ? _c("Tree", {
            ref: "tree",
            attrs: {
              data: _vm.resourceList,
              render: _vm.renderTree,
              "show-checkbox": "",
              multiple: ""
            }
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.merchantRoleItem
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.saveForm("handleAddMerchantRoleItem")
                    }
                  }
                },
                [_vm._v("添加角色 ")]
              )
            : _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.saveForm("handleUpdateMerchantRoleItem")
                    }
                  }
                },
                [_vm._v("修改角色 ")]
              )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }