<template>
    <div>
        <!-- 充电站权限modal -->
        <Modal v-model="showChargeStation" width="500">
            <div slot="header">
                <p>选择充电站</p>
            </div>
            <FormLabel label="是否查询全部站点" :required="true" width="130">
                <RadioGroup v-model="getAllCs">
                    <Radio :label="item.key" v-for="item in allCsList" :key="item.key">
                    {{ item.value }}
                    </Radio>
                </RadioGroup>
            </FormLabel>
            <FormLabel label="选择充电站" :required="true">
                <i-select v-model="getCsIdList" multiple placeholder="请选择">
                    <i-option :value="index.id" v-for="index in stationList" :key="index.id">{{index.name}}</i-option>
                </i-select>
            </FormLabel>
            <div slot="footer">
                <i-button type="default" @click="handleCancel">取消</i-button>
                <i-button type="primary" :loading="loading" @click="handleAdd">确认</i-button>
            </div>
        </Modal>
        <!--角色表单模态框-->
        <div v-if="showDialog">
            <merchant-role-dialog v-model="showDialog" :merchant-role-item="currentMerchantRoleItem"
                                  @on-success="handleSuccess"/>
        </div>

        <Card ref="header">
            <div style="display: flex">
                <Button type="primary" icon="md-add" style="margin-left: auto;" @click="handleAddMerchantRole">新增角色
                </Button>
            </div>
        </Card>
        <!--表格-->
        <Table row-key="id" :tooltip-theme="$store.state.theme" :height="tableHeight" :loading="loading"
               :columns="tableColumns" :data="tableData" stripe border></Table>

        <!-- 分页 -->
        <Page ref="footer" :total="total" :current="currentPage" show-total show-sizer @on-change="handleCurrentChange"
              @on-page-size-change="handlePageSize" style="float: right;"/>
    </div>
</template>

<script>
    import FormLabel from '_c/form/FormLabel.vue'
    import tableHeightMixin from '_m/tableHeight.mixin'
    import tablePageMixin from '_m/tablePage.mixin'
    import MerchantRoleDialog from './MerchantRoleDialog'

    export default {
        name: 'MerchantRole',
        mixins: [tableHeightMixin, tablePageMixin],
        components: {
            MerchantRoleDialog,
            FormLabel
        },
        data () {
            return {
                loading: false,
                tableData: [],
                showDialog: false,
                currentMerchantRoleItem: null,
                showChargeStation: false,
                merchantRoleId: '',
                stationList: [],
                getCsIdList: [],
                getAllCs: 0,
                allCsList: [
                    {
                        key: 0,
                        value: '否'
                    },
                    {
                        key: 1,
                        value: '是'
                    }
                ]
            }
        },
        created () {
            this.initData()
        },
        computed: {
            tableColumns () {
                let columns = []
                columns.push({
                    title: '角色名称',
                    tree: true,
                    key: 'name',
                    align: 'center',
                })
                columns.push({
                    title: '角色描述',
                    key: 'description',
                    align: 'center',
                })
                columns.push({
                    title: '操作',
                    align: 'center',
                    width: 350,
                    render: (h, params) => {
                        const array = []
                        const { row } = params
                        if (row.type !== 1) {
                            array.push(h('Button', {
                                style: {
                                    marginRight: '5px'
                                },
                                props: {
                                    icon: 'ios-cog',
                                    type: 'warning',
                                    size: 'small'
                                },
                                on: {
                                    click: () => {
                                        this.handleUpdateMerchantRoleItem(row)
                                    }
                                }
                            }, '功能权限'))
                            array.push(h('Button', {
                                style: {
                                    marginRight: '5px'
                                },
                                props: {
                                    icon: 'ios-cog',
                                    type: 'warning',
                                    size: 'small'
                                },
                                on: {
                                    click: () => {
                                        this.handleUpdateChargeStation(row)
                                    }
                                }
                            }, '充电站权限'))
                        }
                        array.push(h('Button', {
                            props: {
                                icon: 'ios-trash',
                                type: 'error',
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.handleDeleteMerchantRoleItem(row)
                                }
                            }
                        }, '删除'))
                        return h('div', array)
                    }
                })
                return columns
            }
        },
        watch: {
            showChargeStation(val) {
                if (val) {
                    this.getChargeStation()
                    this.findChooseStation()
                }
            }
        },
        methods: {
            initData () {
                this.loading = true
                this.$http.post(`/server/v1/MerchantRole/page`, {
                    pageNo: this.currentPage,
                    pageSize: this.pageSize,
                    t: {
                        status: 0
                    }
                }).then((response) => {
                    let { rows, code, msg, totalRows } = response
                    if (rows) {
                        this.tableData = rows
                        this.total = totalRows
                    } else {
                        this.$Notice.error({
                            title: `接口报错:code=${code}`,
                            desc: msg
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleSuccess () {
                this.initData()
            },
            // 添加角色
            handleAddMerchantRole () {
                this.currentMerchantRoleItem = null
                this.showDialog = true
            },
            // 修改角色
            handleUpdateMerchantRoleItem (menuItem) {
                this.currentMerchantRoleItem = menuItem
                this.showDialog = true
            },
            // 删除角色
            handleDeleteMerchantRoleItem (merchantRole) {
                this.$Modal.confirm({
                    title: '删除确认',
                    content: `<p>确认删除商家-${merchantRole.name}?</p>`,
                    onOk: () => {
                        this.$http.delete(`/server/v1/MerchantRole/delete/${merchantRole.id}`).then((response) => {
                            const { code, msg } = response
                            if (code === 1) {
                                this.initData()
                            } else {
                                this.$Notice.error({
                                    title: `接口报错:code=${code}`,
                                    desc: msg
                                })
                            }
                        })
                    }
                })
            },
            // 充电站权限
            handleUpdateChargeStation(row) {
                this.showChargeStation = true
                this.merchantRoleId = row.id
                this.getAllCs = row.allCs
            },
            // 回显
            findChooseStation() {
                let a = {
                    merchantRoleId: this.merchantRoleId
                }
                this.$http.post(`/portal/v1/merchant-role-cs/m/findListByPage?page=${1}&pageCount=${1000}`, a)
                .then((response) => {
                    const { code, data } = response
                    if (code === 1) {
                        if (data.records.length > 0) {
                            this.getCsIdList = data.records.map(item => item.csId)
                            console.log('111', this.getCsIdList)
                        }
                    }
                })
            },
            handleCancel() {
                this.showChargeStation = false
            },
            handleAdd() {
                this.loading = true
                this.$http.post(`/portal/v1/merchant-role-cs/m/update`, {
                    merchantRoleId: this.merchantRoleId,
                    csIdList: this.getCsIdList,
                    allCs: this.getAllCs
                }).then((response) => {
                    let { code, msg } = response
                    if (code === 1) {
                        this.$Notice.success({
                            title: `更新充电站权限成功`
                        })
                        this.showChargeStation = false
                    } else {
                        this.$Notice.error({
                            title: `接口报错:code=${code}`,
                            desc: msg
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            // 获取充电站
            getChargeStation() {
                let a = {
                    province: null
                }
                this.$http.post(`/portal/v1/biz-charge-station/m/listBySelf?page=${1}&pageCount=${1000}`, a)
                .then((response) => {
                    const { code, data } = response
                    if (code === 1) {
                        this.stationList = data.records
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .menu-option {
        display: flex;
        margin-bottom: 10px;
    }

    .m-t {
        margin-top: 16px;
    }
</style>
