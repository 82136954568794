<template>
    <Modal v-model="showDialog">
        <template slot="header">
            <Icon type="ios-information-circle"></Icon>
            <span>{{title}}</span>
        </template>
        <Form ref="formValidate" :model="merchantRole" :rules="rules" :label-width="120">
            <FormItem label="角色名称" prop="name">
                <Input v-model="merchantRole.name" placeholder="请输入角色名称"></Input>
            </FormItem>
            <FormItem label="角色描述" prop="description">
                <Input v-model="merchantRole.description" placeholder="请输入角色描述"></Input>
            </FormItem>
        </Form>

        <!-- 资源树 -->
        <Divider/>
        <Tree ref="tree" :data="resourceList" :render="renderTree" show-checkbox multiple v-if="resourceList.length">
        </Tree>

        <template slot="footer">
            <Button type="primary" :loading="loading" @click="saveForm('handleAddMerchantRoleItem')"
                    v-if="!merchantRoleItem">添加角色
            </Button>
            <Button type="primary" @click="saveForm('handleUpdateMerchantRoleItem')" :loading="loading" v-else>修改角色
            </Button>
        </template>
    </Modal>
</template>

<script>
    import dialogMixin from '_m/dialog.mixin'

    export default {
        name: 'MerchantDialog',
        mixins: [dialogMixin],
        data () {
            return {
                showDialog: true,
                loading: false,
                merchantRole: {
                    name: '',
                    description: ''
                },
                resourceList: [],
                checkedResourceObj: {}
            }
        },
        created () {
            if (this.merchantRoleItem) {
                Object.assign(this.merchantRole, this.merchantRoleItem)
                this.getMerchantRoleResourcePromise = this.getMerchantRoleResource()
            }

            this.getUserResource()
        },
        computed: {
            title () {
                return this.merchantRoleItem ? '修改角色' : '添加角色'
            },
            rules () {
                const rules = {
                    name: [
                        { required: true, message: '角色名字不能为空', trigger: 'blur' }
                    ]
                }
                return rules
            }
        },
        props: {
            merchantRoleItem: {
                type: Object,
                required: false,
                default: () => null
            }
        },
        methods: {
            // 获取角色拥有的资源列表
            getMerchantRoleResource () {
                return this.$http.get(`/server/v1/MerchantRole/resource/${this.merchantRole.id}`, {
                    params: {
                        refresh: true
                    }
                }).then(response => {
                    const { code, data, msg } = response

                    if (code === 1) {
                        data.forEach(val => {
                            this.checkedResourceObj[val] = true
                        })
                    } else {
                        this.$Notice.error({
                            title: `code=${code}`,
                            desc: msg
                        })
                    }
                })
            },
            // 获取商家菜单
            getUserResource () {
                this.$http.get('/server/v1/Merchant/Auth/user/resource', {
                    params: {
                        refresh: true
                    }
                }).then(response => {
                    const { code, data, msg } = response

                    if (code === 1) {
                        if (this.getMerchantRoleResourcePromise) {
                            this.getMerchantRoleResourcePromise.then(() => {
                                this.buildTreeData(data.resource)
                                this.resourceList = data.resource
                            })
                        } else {
                            this.resourceList = data.resource
                        }
                    } else {
                        this.$Notice.error({
                            title: `code=${code}`,
                            desc: msg
                        })
                    }
                })
            },
            // 构建已选中资源以符合tree数据格式
            buildTreeData (list) {
                list.forEach(item => {
                    if (this.checkedResourceObj[item.id]) {
                        if (item.children && item.children.length) {
                            item.selected = true
                            this.buildTreeData(item.children)
                        } else {
                            item.checked = true
                        }
                    }
                })
            },
            // 自定义渲染树
            renderTree (h, { root, node, data }) {
                return h('span', data.name)
            },
            // 保存数据到后端
            saveForm (funcName) {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        const checkedNodes = this.$refs.tree.getCheckedAndIndeterminateNodes()
                        this.merchantRole.resources = checkedNodes.map(item => item.id)

                        this[funcName](this.merchantRole).then((response) => {
                            let { code, msg } = response
                            if (code === 1) {
                                this.$emit('on-success')
                                this.showDialog = false
                            } else {
                                this.$Notice.error.error({
                                    title: `code=${code}`,
                                    desc: msg
                                })
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$Notice.error.error({
                            title: '表单填写错误',
                            desc: '请正确填写表单'
                        })
                    }
                })
            },
            //  添加表单项
            handleAddMerchantRoleItem (data) {
                return this.$http.post('/server/v1/MerchantRole/add', data)
            },
            // 修改表单项
            handleUpdateMerchantRoleItem (data) {
                return this.$http.post(`/server/v1/MerchantRole/update/${data.id}`, data)
            }
        }
    }
</script>

<style scoped>
</style>
