<template>
    <div class="flex container">
        <div class="flex flex-content-end" :style="`flex-basis: ${width}`">
            {{label}} <span class="red" :class="required ? '': 'hidden'"> &nbsp;*</span>
        </div>
        <div class="flex flex-content-start">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        width: {
            type: String,
            default: '100px'
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
.container{
    margin: 12px 0;
}
.flex-content-end{
    padding-right: 10px;
    line-height: 2em;
    flex-shrink: 0;
}
.hidden{
    visibility: hidden;
}
.red {
    color: red;
}
</style>
