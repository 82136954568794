var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex container" }, [
    _c(
      "div",
      {
        staticClass: "flex flex-content-end",
        style: "flex-basis: " + _vm.width
      },
      [
        _vm._v(" " + _vm._s(_vm.label) + " "),
        _c(
          "span",
          { staticClass: "red", class: _vm.required ? "" : "hidden" },
          [_vm._v(" *")]
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "flex flex-content-start" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }